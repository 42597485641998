import React from 'react';
import './HomePage.css';
import logo from '../images/logo_boardwalk_white.png';

const HomePage: React.FC = () => {
  return (
    <div className="home-background">
      <div className="home-info">
        <img src={logo} alt="Logo" className="home-logo" />
        <div className="home-address">
          <h1>BOARDWALK</h1>
          <p>Robert Orlentpromenade<br />9 - 9A - 9B - 11 - 11A<br />8620 Nieuwpoort</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
