import React, { useState, useEffect, useMemo } from 'react'; // Importing useState and useEffect
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gpx';
import L, { LatLngExpression } from 'leaflet';
import './RoutesPage.css';
import pinIconStart from '../images/pin-icon-start.png';
import pinIconEnd from '../images/pin-icon-end.png';
import pinIconWpt from '../images/pin-icon-transparent.png';
import pinShadow from '../images/pin-icon-transparent.png';

const RoutesPage: React.FC = () => {
  const defaultMap: LatLngExpression = [51.137604, 2.741973];
  const [selectedRoute, setSelectedRoute] = useState<string | null>(null);
  const [routeName, setRouteName] = useState<string | null>(null);
  const [routeDistance, setRouteDistance] = useState<string | null>(null);

  const gpxFiles = useMemo(() => [
    'avekapelle-veurne (2).gpx',
    'blankenberge.gpx',
    'blankenberge-light.gpx',
    'de-haan---bredene (1).gpx',
    'de-haan---bredene-via-oudenburg.gpx',
    'diksm-alveringem-de-panne.gpx',
    'diksm-alveringem-veurne.gpx',
    'diksm-houtm.gpx',
    'diksmuide-lo-veurne.gpx',
    'diksmuide-mast-veurne.gpx',
    'diksmuide-west-vleteren-de-panne (1).gpx',
    'diksmuide-west-vleteren-de-panne.gpx',
    'diksmuide-west-vleteren-hondschoote.gpx',
    'frontzate-gits-nwp.gpx',
    'gistel-oudenburg-oostende.gpx',
    'het-zwin.gpx',
    'hondschoote.gpx',
    'hooglede---gits.gpx',
    'ijzer-houtem-de-panne.gpx',
    'jabbeke-damme-blankenberge.gpx',
    'knokke.gpx',
    'koekelare-diksmuide.gpx',
    'koksijde-veurne-diksmuide-frontzate.gpx',
    'kust-lo-reninge.gpx',
    'lange-lisroute.gpx',
    'leffinge.gpx',
    'mannekesvere-beerst-diksmuide-lo-veurne-nwp.gpx',
    'mannekesvere-diksmuide-lo-veurne.gpx',
    'nieuwpoort-de_panne.gpx',
    'oesterput-oostende-keiem.gpx',
    'oostende-bredene.gpx',
    'oostende-stad (1).gpx',
    'oostende-via-kust.gpx',
    'oudenburg-knokke.gpx',
    'polder-blankenberge.gpx',
    'schoorbakkeroute.gpx',
    'ter-hilfe-xl.gpx',
    'the-far-west.gpx',
    'toertje-diksmuide.gpx',
    'torhout-staden-lo.gpx',
    'veurne-xl.gpx',
    'west-vleteren-proven.gpx',
    'wings-for-freedom.gpx',
    'wulpen-bulskamp.gpx',
    'zuienkerke.gpx',
  ], []);

  // Function to remove .gpx extension from file name
  const removeExtension = (filename: string) => {
    return filename.replace('.gpx', '');
  };
  const convertMetersToKilometers = (meters: string | null) => {
    if (!meters) return null;
    const kilometers = parseFloat(meters) / 1000;
    return kilometers.toFixed(2); // Convert to kilometers and format to 2 decimal places
  };

  const handleRouteClick = (filename: string) => {
    setSelectedRoute(filename);
  };

  const [maxWidth, setMaxWidth] = useState<number>(500);

  useEffect(() => {
    // Calculate the width based on the longest filename
    const longestFilenameLength = Math.max(...gpxFiles.map(file => removeExtension(file).length));
    const calculatedWidth = Math.min(longestFilenameLength * 10, 500); // Adjust the multiplier as needed
    setMaxWidth(calculatedWidth);
  }, [gpxFiles]);

  return (
    <div className="routes-page">
      <div className="left-pane" style={{ maxWidth: maxWidth }}>
        <h2>Routes</h2>
        <ul>
          {gpxFiles.map((file, index) => (
            <li key={index} onClick={() => handleRouteClick(file)}>
              {removeExtension(file)}
            </li>
          ))}
        </ul>
      </div>
      <div className="map-pane">
        <h1>Ontdek fietsroute: {removeExtension(selectedRoute || '')}</h1>
        {selectedRoute && (
          <p>
            Naam: {routeName}<br/>
            Afstand: {convertMetersToKilometers(routeDistance)} km<br />
            Download gpx: <a href={`gpxfiles/${selectedRoute}`} download>{selectedRoute}</a><br />
          </p>
        )}
        <MapContainer center={defaultMap} zoom={13} scrollWheelZoom={false} style={{ height: '600px', width: '100%' }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {selectedRoute &&
            <MapWithGPX
              selectedRoute={selectedRoute}
              setRouteName={setRouteName}
              setRouteDistance={setRouteDistance}
            />}
        </MapContainer>
      </div>
    </div>
  );
};

interface MapWithGPXProps {
  selectedRoute: string | null;
  setRouteName: React.Dispatch<React.SetStateAction<string | null>>;
  setRouteDistance: React.Dispatch<React.SetStateAction<string | null>>;
}

const MapWithGPX: React.FC<MapWithGPXProps> = ({
  selectedRoute,
  setRouteName,
  setRouteDistance
}) => {
  const map = useMap();

  useEffect(() => {
    if (!selectedRoute) return;

    // Fetch the selected GPX file
    fetch(`/gpxfiles/${selectedRoute}`)
      .then((response) => response.text())
      .then((gpxData) => {
        new L.GPX(gpxData, {
          async: true,
          marker_options: {
            startIconUrl: pinIconStart,
            endIconUrl: pinIconEnd,
            wptIconUrls: {
              '': pinIconWpt,
              'Geocache Found': pinIconWpt,
              'Park': pinIconWpt
            },
            shadowUrl: pinShadow,
          }
        }).on('loaded', function(e) {
          map.fitBounds(e.target.getBounds());
          setRouteName(e.target.get_name());
          setRouteDistance(e.target.get_distance());
        }).addTo(map);
      });

    return () => {
      // Clean up the map when the component unmounts or when another route is selected
      map.eachLayer((layer) => {
        if (layer instanceof L.GPX) {
          map.removeLayer(layer);
        }
      });
    };
  }, [selectedRoute, map, setRouteName, setRouteDistance]);

  return null;
};

export default RoutesPage;
