import React from 'react';
import NavBar from './NavBar';

interface ILayout {
    children: any
}

const Layout: React.FC <ILayout> = ({ children }) => {
  return (
    <>
      <NavBar />
      <main style={{ marginTop: '60px' }}>  {/* Adjust the margin to fit the nav height */}
        {children}
      </main>
    </>
  );
};

export default Layout;
