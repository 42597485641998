import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './components/HomePage';
import RoutesPage from './components/RoutesPage';
import PlacesPage from './components/PlacesPage';

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/routes" element={<RoutesPage />} />
          <Route path="/places" element={<PlacesPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
