import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

const NavBar: React.FC = () => {
  return (
    <nav className="nav">
      <ul>
        <li className="current" data-hover="Home"><Link to="/">Home</Link></li>
        <li data-hover="Routes"><Link to="/routes">Fietsroutes</Link></li>
        <li data-hover="Places"><Link to="/places">Eet- en drinkgelegenheden</Link></li>
      </ul>
    </nav>
  );
};

export default NavBar;
